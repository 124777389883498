import { Fragment, useEffect, useMemo, useState } from "react";
import BreadCrumb from "../../../common/BreadCrumb";
import Utils from "../../../common/ultis";
import { MaterialReactTable } from "material-react-table";
import moment from "moment";
import useApi from "../../../../hooks/useApi";
import RequisitionDeatils from "../requisitionDetails/requisitionDetails";

const RequisitionApproved = () => {
  const [requisition, setRequisition] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRequisitionDetails, setSelectedRequisitionDetails] =
    useState("");
  const api = useApi();
  useEffect(() => {
    fetchRequisition();
  }, []);

  const fetchRequisition = async () => {
    const requisitionList = await api.getAllApprovedRequisition();
    if (requisitionList && requisitionList.length > 0) {
      await setRequisition(requisitionList);
      setLoading(false);
    } else {
      await setRequisition([]);
      setLoading(false);
    }
  };

  const handleDetails = async (id) => {
    const requisitionDetails = await api.getSingleRequisitionDetails(id);
    if (requisitionDetails && requisitionDetails.length > 0) {
      await setSelectedRequisitionDetails(requisitionDetails);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "requsition_no",
        header: "Requisition Number",
        size: 300,
      },
      {
        accessorKey: "requisition_code",
        header: "Requisition Code",
        size: 200,
      },
      {
        accessorKey: "isApproved",
        header: "Approved Status",
        size: 200,
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        size: 300,
        Cell: ({ cell }) => moment(cell.getValue()).format("LLLL"),
      },
      {
        accessorKey: "id",
        header: "Actions",
        size: 300,
        Cell: ({ cell }) => (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => handleDetails(cell.row.original.id)}
            >
              Details
            </button>
          </div>
        ),
      },
    ],
    []
  );

  if (loading) {
    return (
      <>
        <div>
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-secondary" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-success" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-danger" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-warning" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-info" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-light" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-dark" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      </>
    );
  }

  return (
    <Fragment>
      <Utils />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <BreadCrumb
          title="Approved Requisition List"
          link="/requisition/add"
          linkTitle="Add new"
        />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {/* /.card */}
                <div className="card">
                  <MaterialReactTable columns={columns} data={requisition} />
                </div>
                <div className="card">
                  {selectedRequisitionDetails &&
                    selectedRequisitionDetails.length > 0 && (
                      <RequisitionDeatils data={selectedRequisitionDetails} display="approvedrequisition" />
                    )}
                </div>

                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </Fragment>
  );
};

export default RequisitionApproved;
