import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";

const useProduct = () => {
  const api = useApi();
  const [product, setProduct] = useState();
  useEffect(() => {
    fetchCategoryInformation();
  }, []);

  const fetchCategoryInformation = async () => {
    const productList = await api.getAllProduct();
    if (productList && productList.length > 0) {
      await setProduct(productList);
    } else {
      setProduct([]);
    }
  };

  return [product, setProduct];
};

export default useProduct;
