import "./App.css";
import Footer from "./components/common/Footer.jsx";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Product from "./components/pages/product/Product.jsx";
import Category from "./components/pages/category/Category.jsx";
import Login from "./components/pages/login/Login";
import AddCategory from "./components/pages/category/addCategory/AddCategory.jsx";
import Brand from "./components/pages/brand/Brand.jsx";
import AddBrand from "./components/pages/brand/addBrand/AddBrand.jsx";
import Supplier from "./components/pages/supplier/Supplier.jsx";
import AddSupplier from "./components/pages/supplier/AddSupplier/AddSupplier.jsx";
import AddProduct from "./components/pages/product/addProduct/AddProduct.jsx";
import RequisitionPending from "./components/pages/requisition/requisitionPending/requisitionPending.jsx";
import RequisitionAdd from "./components/pages/requisition/requisitionAdd/requisitionAdd.jsx";
import RequisitionApproved from "./components/pages/requisition/requisitionApproved/requisitionApproved.jsx";
import PurchaseAdd from "./components/pages/purchase/purchaseAdd.jsx";

function App() {
  return (
    <BrowserRouter>
      <div className="wrapper">
        <Routes>
          <Route index path="/" element={<Login />}></Route>
          <Route path="/category" element={<Category />} />
          <Route path="/add-category" element={<AddCategory />} />
          <Route path="/brand" element={<Brand />} />
          <Route path="/add-brand" element={<AddBrand />} />
          <Route path="/supplier" element={<Supplier />} />
          <Route path="/add-supplier" element={<AddSupplier />} />
          <Route path="/product" element={<Product />} />
          <Route path="/add-product" element={<AddProduct />} />
          <Route path="/requisition/pending" element={<RequisitionPending />} />
          <Route
            path="/requisition/approved"
            element={<RequisitionApproved />}
          />
          <Route path="/requisition/add" element={<RequisitionAdd />} />

          <Route path="/purchase/add" element={<PurchaseAdd />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
