import { Fragment, useRef } from "react";
import Utils from "../../../common/ultis";
import { Link, useNavigate } from "react-router-dom";
import useApi from "../../../../hooks/useApi";
import BreadCrumb from "../../../common/BreadCrumb";

const AddCategory = () => {
  const categoryRef = useRef("");
  const api = useApi();

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const categoryName = categoryRef.current.value;

    const insertCategory = await api.addCategory(categoryName);
    // console.log(insertCategory, "category");
    if (insertCategory && insertCategory.id != null) {
      navigate("/category");
    }
  };

  return (
    <Fragment>
      <Utils />
      <div className="content-wrapper">
        {/* breadcrumb */}
        <BreadCrumb
          title="Add New Category"
          link="/category"
          linkTitle="Back"
        />
        {/* breadcrumb */}
        {/* main section */}
        <section className="content mt-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 mx-auto">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Add New Category</h3>
                  </div>
                  <form>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Category Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Category Name"
                          ref={categoryRef}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button
                        onClick={handleSubmit}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* main section */}
      </div>
    </Fragment>
  );
};

export default AddCategory;
