import { Link } from "react-router-dom";
import ExportToExcel from "../../operations/ExportToExcel";

const BreadCrumb = ({ link, linkTitle, title, data }) => {
  return (
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-10">
            <h1 className="text-center">{title}</h1>
          </div>
          <div className="col-sm-1">
            <Link className="btn btn-primary" to={link}>
              {linkTitle}
            </Link>
          </div>
          {data && data.length > 0 && (
            <div className="col-sm-1">
              <ExportToExcel data={data} fileName="categorylist" />
            </div>
          )}
        </div>
      </div>
      {/* /.container-fluid */}
    </section>
  );
};

export default BreadCrumb;
