import { Fragment } from "react";
import Navbar from "./Navbar";
import SideBar from "./SideBar";

const Utils = () => {
  return (
    <Fragment>
      <Navbar />
      <SideBar />
    </Fragment>
  );
};

export default Utils;
