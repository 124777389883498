import { Fragment, useEffect, useMemo, useState } from "react";
import useApi from "../../../hooks/useApi";
import Utils from "../../common/ultis";
import { MaterialReactTable } from "material-react-table";
import moment from "moment";
import { Link } from "react-router-dom";
import BreadCrumb from "../../common/BreadCrumb";

const Brand = () => {
  const api = useApi();
  const [brand, setBrand] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDeleted, setDeleted] = useState(true);
  useEffect(() => {
    fetchCategoryInformation();
  }, []);

  const fetchCategoryInformation = async () => {
    const brandList = await api.getAllBrand();
    if (brandList && brandList.length > 0) {
      await setBrand(brandList);
      setLoading(false);
    } else {
      setBrand([]);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    let deleteCategory = await api.deleteBrand(id);
    if (deleteCategory.isExecute === true) {
      const newCategory = await brand.filter((cat) => cat.id !== id);
      await setBrand(newCategory);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
      },
      {
        accessorKey: "remarks",
        header: "Remarks",
        size: 300,
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        size: 300,
        Cell: ({ cell }) => moment(cell.getValue()).format("LLLL"),
      },
      {
        accessorKey: "updatedAt",
        header: "Updated At",
        size: 300,
        Cell: ({ cell }) => moment(cell.getValue()).format("LLLL"),
      },

      {
        accessorKey: "id",
        header: "Actions",
        size: 300,
        Cell: ({ cell }) => (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => handleDelete(cell.row.original.id)}
            >
              Delete
            </button>
            {/* <button onClick={() => handleAdd(cell.row.original.id)}>Add</button> */}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Fragment>
      <Utils />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <BreadCrumb title="Brand list" linkTitle="Add new" link="/add-brand" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {/* /.card */}
                <div className="card">
                  <MaterialReactTable columns={columns} data={brand} />
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </Fragment>
  );
};

export default Brand;
