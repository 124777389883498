const RequisitionTableView = ({
  selectedProducts,
  handleProductPrice,
  handleProductQuantity,
}) => {
  var grandTotal = 0;
  const totalPrice = (unit_price, quantity) => {
    console.log(unit_price, quantity);
    if (unit_price === undefined || quantity === undefined) {
      return 0;
    }
    return unit_price * quantity;
  };

  const grandTotalCalculation = () => {
    if (selectedProducts.length > 0) {
      selectedProducts.map((product) => {
        grandTotal +=
          parseInt(product.unit_price ? product.unit_price : 0) *
          parseInt(product.quantity ? product.quantity : 0);
      });
      return grandTotal;
    }
    return grandTotal;
  };

  return (
    <>
      {selectedProducts.map((product, index) => (
        <tr key={product.product_id}>
          <td>{product.product_name}</td>
          <td>{product.product_code}</td>
          <td>
            <input
              className="form-control"
              onChange={(e) => handleProductPrice(index, e)}
              defaultValue={0}
            />
          </td>
          <td>
            <input
              className="form-control"
              onChange={(e) => handleProductQuantity(index, e)}
              defaultValue={0}
            />
          </td>
          <td>{totalPrice(product.unit_price, product.quantity)}</td>
        </tr>
      ))}
      <tr>
        <td colSpan={3}></td>
        <td>Grand Total</td>
        <td>{grandTotalCalculation()}</td>
      </tr>
    </>
  );
};

export default RequisitionTableView;
