import { Fragment, useRef } from "react";
import Utils from "../../../common/ultis";
import { Link, useNavigate } from "react-router-dom";
import useApi from "../../../../hooks/useApi";
import BreadCrumb from "../../../common/BreadCrumb";

const AddSupplier = () => {
  const supplierNameRef = useRef("");
  const supplierEmailRef = useRef("");
  const supplierPhoneNumberRef = useRef("");
  const supplierAddressRef = useRef("");
  const supplierCityRef = useRef("");
  const supplierShopName = useRef("");
  const api = useApi();

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const SupplierName = supplierNameRef.current.value;
    const SupplierEmail = supplierEmailRef.current.value;
    const SupplierPhone = supplierPhoneNumberRef.current.value;
    const SupplierAddress = supplierAddressRef.current.value;
    const SupplierCity = supplierCityRef.current.value;
    const SupplierShop = supplierShopName.current.value;

    const data = {
      supplier_name: SupplierName,
      email: SupplierEmail,
      phone: SupplierPhone,
      address: SupplierAddress,
      city: SupplierCity,
      shop_name: SupplierShop,
    };
    const insertSupplier = await api.addSupplier(data);
    if (insertSupplier && insertSupplier.id != null) {
      navigate("/supplier");
    }
  };

  return (
    <Fragment>
      <Utils />
      <div className="content-wrapper">
        {/* breadcrumb */}
        <BreadCrumb
          title="Add New Supplier"
          link="/supplier"
          linkTitle="Back"
        />
        {/* breadcrumb */}
        {/* main section */}
        <section className="content mt-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 mx-auto">
                <div className="card card-primary">
                  <div className="card-header text-center">
                    <h3 className="card-title">Add New Supplier</h3>
                  </div>
                  <form>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Supplier Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Supplier Name"
                          ref={supplierNameRef}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Supplier Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Supplier Email"
                          ref={supplierEmailRef}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Supplier Phone Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Supplier Name"
                          ref={supplierPhoneNumberRef}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Supplier Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Supplier Address"
                          ref={supplierAddressRef}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Supplier City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Supplier Name"
                          ref={supplierCityRef}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Supplier Shop Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Supplier Shop Name"
                          ref={supplierShopName}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button
                        onClick={handleSubmit}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* main section */}
      </div>
    </Fragment>
  );
};

export default AddSupplier;
