import { Fragment } from "react";

const Footer = () => {
  return (
    <Fragment>
      <footer className="main-footer">
        <div className="float-right d-none d-sm-block">
          <b>Version</b> 3.2.0
        </div>
        <strong>
          Copyright © 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.
        </strong>{" "}
        All rights reserved.
      </footer>
      <aside className="control-sidebar control-sidebar-dark"></aside>
    </Fragment>
  );
};
export default Footer;
