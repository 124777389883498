import { atom } from "recoil";

export const userInformationAtom = atom({
  key: "userInfo",
  default: localStorage.getItem("email") ? localStorage.getItem("email") : "",
});

export const selectedRequisitionAtom = atom({
  key: "selectedRequisition",
  default: [],
});
