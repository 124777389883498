import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";

const useBrand = () => {
  const api = useApi();
  const [brands, setBrands] = useState();
  useEffect(() => {
    fetchBrandInformation();
  }, []);

  const fetchBrandInformation = async () => {
    const brandList = await api.getAllBrand();
    if (brandList && brandList.length > 0) {
      await setBrands(brandList);
    } else {
      setBrands([]);
    }
  };

  return [brands, setBrands];
};

export default useBrand;
