import { Fragment, useRef } from "react";
import Utils from "../../../common/ultis";
import { Link, useNavigate } from "react-router-dom";
import useApi from "../../../../hooks/useApi";
import BreadCrumb from "../../../common/BreadCrumb";

const AddBrand = () => {
  const brandRef = useRef("");
  const remarksRef = useRef("");
  const api = useApi();

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const brandName = brandRef.current.value;
    const remarks = remarksRef.current.value;
    const data = { name: brandName, remarks: remarks };
    const insertBrand = await api.addBrand(data);
    if (insertBrand && insertBrand.id != null) {
      navigate("/brand");
    }
  };

  return (
    <Fragment>
      <Utils />
      <div className="content-wrapper">
        {/* breadcrumb */}
        <BreadCrumb title="Add New Brand" link="/brand" linkTitle="back" />
        {/* breadcrumb */}
        {/* main section */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mx-auto">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Add New Brand</h3>
                  </div>
                  <form>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Brand Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Brand Name"
                          ref={brandRef}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Remarks</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Remarks"
                          ref={remarksRef}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button
                        onClick={handleSubmit}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* main section */}
      </div>
    </Fragment>
  );
};

export default AddBrand;
