import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";

const useSupplier = () => {
  const api = useApi();
  const [supplier, setSupplier] = useState();
  useEffect(() => {
    fetchCategoryInformation();
  }, []);

  const fetchCategoryInformation = async () => {
    const supplierList = await api.getAllSupplier();
    if (supplierList && supplierList.length > 0) {
      await setSupplier(supplierList);
    } else {
      setSupplier([]);
    }
  };

  return [supplier, setSupplier];
};

export default useSupplier;
