import { Fragment, useRef, useState } from "react";
import BreadCrumb from "../../../common/BreadCrumb";
import Utils from "../../../common/ultis";
import useProduct from "../../../hooks/useProduct";
import RequisitionTableView from "./requisitionTableView";
import useApi from "../../../../hooks/useApi";
import { useNavigate } from "react-router-dom";

const RequisitionAdd = () => {
  const requisitionNoRef = useRef("");
  const productNameRef = useRef(null);
  const requisitionCodeRef = useRef("");

  const [product, setProduct] = useProduct();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState();
  const api = useApi();
  const navigation = useNavigate();

  const handleSubmit = async () => {
    const productCode = productNameRef.current.value;
    const selectedProduct = product.find(
      (pro) => pro.product_code === productCode
    );
    /*****if find any product ******/
    if (selectedProduct) {
      setSelectedProducts([
        ...selectedProducts,
        {
          product_id: selectedProduct.id,
          product_name: selectedProduct.product_name,
          product_code: selectedProduct.product_code,
          unit_price: 0,
          quantity: 0,
        },
      ]);
    }
    /*****if find any product ******/
  };

  const handleProductPrice = async (index, e) => {
    const newPrice = parseInt(e.target.value);
    await setSelectedProducts((prevProduct) => {
      const updatedProduct = [...prevProduct];
      updatedProduct[index] = {
        ...updatedProduct[index],
        unit_price: parseInt(newPrice),
      };
      return updatedProduct;
    });
  };

  const handleProductQuantity = async (index, e) => {
    const quantity = parseInt(e.target.value);
    await setSelectedProducts((prevProduct) => {
      const updatedProduct = [...prevProduct];
      updatedProduct[index] = {
        ...updatedProduct[index],
        quantity: parseInt(quantity),
      };
      return updatedProduct;
    });
  };

  const handleFormSubmit = () => {
    const data = {
      requsition_no: requisitionNoRef.current.value,
      requisition_code: requisitionCodeRef.current.value,
      isApproved: 0,
      created_by: 1,
      details: selectedProducts,
    };

    const inserted = api.addRequisition(data);
    if (inserted) {
      navigation("/requisition/pending");
    }
  };

  return (
    <Fragment>
      <Utils />
      <div className="content-wrapper">
        {/* breadcrumb */}
        <BreadCrumb
          title="Add New Requisition"
          link="/requisition"
          linkTitle="Back"
        />

        {/* breadcrumb */}
        {/* main section */}
        <section className="content mt-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Add New Requisition</h3>
                  </div>
                  {/* <form> */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Requisition No
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Requisition Number"
                            ref={requisitionNoRef}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Requisition No
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Requisition Code"
                            ref={requisitionCodeRef}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="form-group">
                          <label for="exampleSelectRounded0">
                            Product Name
                          </label>
                          <select
                            class="custom-select rounded-0"
                            id="exampleSelectRounded0"
                            ref={productNameRef}
                          >
                            <option selected value="">
                              select
                            </option>
                            {product &&
                              product.map((pro) => (
                                <option key={pro.id} value={pro.product_code}>
                                  {pro.product_name}({pro.product_code})
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 ">
                        <button
                          onClick={handleSubmit}
                          type="submit"
                          className="btn btn-primary mt-4"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Requisition Details</h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className="tableCell">Product Name</th>
                          <th className="tableCell">Product Code</th>
                          <th className="tableCell">Unit Price</th>
                          <th className="tableCell">Quantity</th>
                          <th className="tableCell">Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr> */}
                        <RequisitionTableView
                          selectedProducts={selectedProducts}
                          handleProductPrice={handleProductPrice}
                          handleProductQuantity={handleProductQuantity}
                        />
                        {/* </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="card-footer">
          <button
            onClick={handleFormSubmit}
            type="submit"
            className="btn btn-primary"
          >
            Submit
          </button>
        </div>
        {/* main section */}
      </div>
    </Fragment>
  );
};

export default RequisitionAdd;
