import { Fragment, useEffect, useMemo, useState } from "react";
import BreadCrumb from "../../common/BreadCrumb";
import useCategory from "../../hooks/useCategory";
import useProduct from "../../hooks/useProduct";
import Utils from "../../common/ultis";
import moment from "moment";
import { MaterialReactTable } from "material-react-table";
import useApi from "../../../hooks/useApi";

const Product = () => {
  const [product, setProduct] = useProduct();
  const api = useApi();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    console.log(product);
    if (product !== undefined) {
      setLoading(false);
    }
  }, [product]);

  const handleDelete = async (id) => {
    let deleteProduct = await api.deleteProduct(id);
    if (deleteProduct.isExecute === true) {
      const newProduct = await product.filter((pro) => pro.id !== id);
      await setProduct(newProduct);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "product_name",
        header: "Product Name",
        size: 220,
      },
      {
        accessorKey: "product_code",
        header: "Product Code",
        size: 220,
      },
      {
        accessorKey: "brandName",
        header: "Brand Name",
        size: 220,
      },
      {
        accessorKey: "categoryName",
        header: "Category Name",
        size: 220,
      },
      {
        accessorKey: "supplierName",
        header: "Supplier Name",
        size: 220,
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        size: 300,
        Cell: ({ cell }) => moment(cell.getValue()).format("LLLL"),
      },

      {
        accessorKey: "id",
        header: "Actions",
        size: 300,
        Cell: ({ cell }) => (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => handleDelete(cell.row.original.id)}
            >
              Delete
            </button>
            {/* <button onClick={() => handleAdd(cell.row.original.id)}>Add</button> */}
          </div>
        ),
      },
    ],
    []
  );

  if (loading) {
    return (
      <>
        <div>
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-secondary" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-success" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-danger" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-warning" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-info" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-light" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-dark" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      </>
    );
  }

  return (
    <Fragment>
      <Utils />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <BreadCrumb
          title="Product list"
          linkTitle="Add new"
          link="/add-product"
        />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {/* /.card */}
                <div className="card">
                  <MaterialReactTable columns={columns} data={product} />
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </Fragment>
  );
};

export default Product;
