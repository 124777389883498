import useAxios from "./useAxios";

const useApi = () => {
  const axiosClient = useAxios();

  /** get localstorage value */
  const getLocalStorageValue = () => {
    return {
      token: localStorage.getItem("token")
        ? localStorage.getItem("token")
        : null,
      email: localStorage.getItem("email")
        ? localStorage.getItem("email")
        : null,
      role: localStorage.getItem("role") ? localStorage.getItem("role") : null,
    };
  };

  /** calling login api */

  const login = async (data) => {
    const response = await axiosClient.apiClient("POST", "user/login", data);
    if (response) {
      if (response?.data) {
        return response.data;
      }
    } else {
      return { message: response.message, data: [] };
    }
    return null;
  };
  /** calling login api */

  /*************************Category service*************************/

  const getAllCategory = async () => {
    const response = await axiosClient.apiClient("GET", `category`);
    return response.data.data;
  };

  const addCategory = async (name) => {
    const data = { name: name };
    const response = await axiosClient.apiClient("POST", `category`, data);
    return response.data.data;
  };

  const deleteCategory = async (categoryId) => {
    const response = await axiosClient.apiClient(
      "DELETE",
      `category/${categoryId}`
    );
    console.log(response);
    return response.data;
  };

  /*************************Category Service end *************************/

  /*************************brand Service end *************************/
  const getAllBrand = async () => {
    const response = await axiosClient.apiClient("GET", `brand`);
    return response.data.data;
  };

  const addBrand = async (data) => {
    const response = await axiosClient.apiClient("POST", `brand`, data);
    return response.data.data;
  };

  const deleteBrand = async (brandId) => {
    const response = await axiosClient.apiClient("DELETE", `brand/${brandId}`);
    return response.data;
  };

  /*************************brand Service end *************************/

  /*************************Supplier Service end *************************/
  const getAllSupplier = async () => {
    const response = await axiosClient.apiClient("GET", `supplier`);
    return response.data.data;
  };

  const addSupplier = async (data) => {
    const response = await axiosClient.apiClient("POST", `supplier`, data);
    return response.data.data;
  };

  const deleteSupplier = async (supplierId) => {
    const response = await axiosClient.apiClient(
      "DELETE",
      `supplier/${supplierId}`
    );
    return response.data;
  };

  /*************************Supplier Service end *************************/

  /*************************Product Service end *************************/
  const getAllProduct = async () => {
    const response = await axiosClient.apiClient("GET", `product`);
    return response.data.data;
  };

  const addProduct = async (data) => {
    const response = await axiosClient.apiClient("POST", `product`, data);
    return response.data.data;
  };

  const deleteProduct = async (productId) => {
    const response = await axiosClient.apiClient(
      "DELETE",
      `product/${productId}`
    );
    return response.data;
  };

  /*************************Supplier Service end *************************/

  /*************************Requisition Service start *******************/

  const getAllPendingRequisition = async () => {
    const response = await axiosClient.apiClient("GET", `requisition/pending`);
    return response.data.data;
  };

  const getAllApprovedRequisition = async () => {
    const response = await axiosClient.apiClient("GET", `requisition/approved`);
    return response.data.data;
  };

  const getSingleRequisitionDetails = async (id) => {
    const response = await axiosClient.apiClient(
      "GET",
      `requisition/single/details/${id}`
    );
    return response.data.data;
  };

  const addRequisition = async (data) => {
    const response = await axiosClient.apiClient("POST", `requisition/`, data);
    return response.data.data;
  };


  const approvedRequisition = async (id) =>{
    const response = await axiosClient.apiClient("GET", `requisition/approved/${id}`);
    return response.data.isExecute;
  }

  /*************************Requisition Service start *******************/

  return {
    getLocalStorageValue,
    login,
    getAllCategory,
    addCategory,
    deleteCategory,
    getAllBrand,
    addBrand,
    deleteBrand,
    getAllSupplier,
    addSupplier,
    deleteSupplier,
    getAllProduct,
    addProduct,
    deleteProduct,
    getAllPendingRequisition,
    getAllApprovedRequisition,
    getSingleRequisitionDetails,
    addRequisition,
    approvedRequisition
  };
};

export default useApi;
