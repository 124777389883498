import { Fragment, useEffect, useMemo, useState } from "react";
import useApi from "../../../hooks/useApi";
import Utils from "../../common/ultis";
import { MaterialReactTable } from "material-react-table";
import moment from "moment";
import { Link } from "react-router-dom";
import BreadCrumb from "../../common/BreadCrumb";
import { useRecoilState } from "recoil";
import { userInformationAtom } from "../../../states/common";

const Category = () => {
  const api = useApi();
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDeleted, setDeleted] = useState(true);
  const [userInfo, setUserInfo] = useRecoilState(userInformationAtom);

  useEffect(() => {
    console.log(userInfo, "userinfo");
    fetchCategoryInformation();
  }, []);

  const fetchCategoryInformation = async () => {
    const categoryList = await api.getAllCategory();
    if (categoryList && categoryList.length > 0) {
      await setCategory(categoryList);
      setLoading(false);
    } else {
      setCategory([]);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    let deleteCategory = await api.deleteCategory(id);
    if (deleteCategory.isExecute === true) {
      // const newCategory = await category.filter((cat) => cat.id !== id);
      // await setCategory(newCategory);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        size: 300,
        Cell: ({ cell }) => moment(cell.getValue()).format("LLLL"),
      },
      {
        accessorKey: "updatedAt",
        header: "Updated At",
        size: 300,
        Cell: ({ cell }) => moment(cell.getValue()).format("LLLL"),
      },

      {
        accessorKey: "id",
        header: "Actions",
        size: 300,
        Cell: ({ cell }) => (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => handleDelete(cell.row.original.id)}
            >
              Delete
            </button>
            {/* <button onClick={() => handleAdd(cell.row.original.id)}>Add</button> */}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Fragment>
      <Utils />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <BreadCrumb
          title="Category List"
          link="/add-category"
          linkTitle="Add new"
          data={category}
        />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {/* /.card */}
                <div className="card">
                  <MaterialReactTable columns={columns} data={category} />
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </Fragment>
  );
};

export default Category;
