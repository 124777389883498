import { Fragment, useEffect, useMemo, useState } from "react";
import useApi from "../../../hooks/useApi";
import Utils from "../../common/ultis";
import { MaterialReactTable } from "material-react-table";
import moment from "moment";
import { Link } from "react-router-dom";
import BreadCrumb from "../../common/BreadCrumb";

const Supplier = () => {
  const api = useApi();
  const [supplier, setSupplier] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDeleted, setDeleted] = useState(true);
  useEffect(() => {
    fetchSupplierInformation();
  }, []);

  const fetchSupplierInformation = async () => {
    const supplierList = await api.getAllSupplier();
    if (supplierList && supplierList.length > 0) {
      await setSupplier(supplierList);
      setLoading(false);
    } else {
      setSupplier([]);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    let deleteSupplier = await api.deleteSupplier(id);
    if (deleteSupplier.isExecute === true) {
      const newCategory = await supplier.filter((cat) => cat.id !== id);
      await setSupplier(newCategory);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "supplier_name",
        header: "Supplier Name",
        size: 220,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 220,
      },
      {
        accessorKey: "address",
        header: "Address",
        size: 220,
      },
      {
        accessorKey: "city",
        header: "City",
        size: 100,
      },
      {
        accessorKey: "shop_name",
        header: "Shop Name",
        size: 100,
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        size: 300,
        Cell: ({ cell }) => moment(cell.getValue()).format("LLLL"),
      },

      {
        accessorKey: "id",
        header: "Actions",
        size: 300,
        Cell: ({ cell }) => (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => handleDelete(cell.row.original.id)}
            >
              Delete
            </button>
            {/* <button onClick={() => handleAdd(cell.row.original.id)}>Add</button> */}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Fragment>
      <Utils />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <BreadCrumb
          title="Supplier list"
          linkTitle="Add new"
          link="/add-supplier"
        />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {/* /.card */}
                <div className="card">
                  <MaterialReactTable columns={columns} data={supplier} />
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </Fragment>
  );
};

export default Supplier;
