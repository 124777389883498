import { useRecoilState } from "recoil";
import { selectedRequisitionAtom } from "../../../../states/common";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RequisitionDeatils = ({ data, display }) => {
  const [selectedRequisition, setSelectedRequisition] = useRecoilState(
    selectedRequisitionAtom
  );
  console.log(data);
  useEffect(() => {
    setSelectedRequisition(data);
  }, [data, setSelectedRequisition]);

  const toCapitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const rows = data;
  let grandTotal = 0;
  data.map((product) => {
    grandTotal += product.unit_price * product.quantity;
  });
  console.log(grandTotal);

  const columns = [...new Set(rows.flatMap(Object.keys))];
  const navigation = useNavigate();
  const handlePurchasePage = () => {
    navigation("/purchase/add");
  };

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h3 className="card-title">Requisition Details</h3>
                {display === "approvedrequisition" && (
                  <button
                    onClick={handlePurchasePage}
                    style={{ marginLeft: "70%" }}
                    className="btn btn-info mt-1"
                  >
                    Purchase
                  </button>
                )}
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      {columns.map((row, index) => (
                        <th className="tableCell">{toCapitalize(row)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={row.id}>
                        {Object.keys(row).map((key) => (
                          <td className="tableCell" key={key}>
                            {row[key]}
                          </td>
                        ))}
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td>Grand Total</td>
                      <td>{grandTotal}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RequisitionDeatils;
