import { Fragment, useEffect, useRef } from "react";
import Utils from "../../../common/ultis";
import { useNavigate } from "react-router-dom";
import useApi from "../../../../hooks/useApi";
import BreadCrumb from "../../../common/BreadCrumb";
import useCategory from "../../../hooks/useCategory";
import useSupplier from "../../../hooks/useSupplier";
import useBrand from "../../../hooks/useBrand";

const AddProduct = () => {
  const [category, setCategory] = useCategory();
  const [supplier, setSupplier] = useSupplier();
  const [brands, setBrands] = useBrand();
  const unit = ["kg", "pcs"];
  useEffect(() => {}, [brands, supplier, brands]);

  const categoryRef = useRef("");
  const brandRef = useRef("");
  const unitIdRef = useRef("");
  const productNameRef = useRef("");
  const supplierRef = useRef("");
  const productCodeRef = useRef("");
  const productDetails = useRef("");
  const colorRef = useRef("");
  const sizeRef = useRef("");
  const api = useApi();

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const product = {
      cat_id: categoryRef.current.value,
      brand_id: brandRef.current.value,
      unit_id: 1,
      supplier_id: supplierRef.current.value,
      product_name: productNameRef.current.value,
      product_code: productCodeRef.current.value,
      product_details: productDetails.current.value,
      color: colorRef.current.value,
      size: sizeRef.current.value,
    };

    const insertProduct = await api.addProduct(product);
    if (insertProduct && insertProduct.id != null) {
      navigate("/product");
    }
  };

  return (
    <Fragment>
      <Utils />
      <div className="content-wrapper">
        {/* breadcrumb */}
        <BreadCrumb
          title="Add New Category"
          link="/category"
          linkTitle="Back"
        />
        {/* breadcrumb */}
        {/* main section */}
        <section className="content mt-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 mx-auto">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Add New Product</h3>
                  </div>
                  <form>
                    <div className="card-body">
                      {/* product name  */}
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Product Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Product Name"
                          ref={productNameRef}
                        />
                      </div>

                      {/* product code    */}
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Product Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Product code"
                          ref={productCodeRef}
                        />
                      </div>

                      {/* product code    */}
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Product Details
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Product Details"
                          ref={productDetails}
                        />
                      </div>

                      {/* category  */}
                      <div class="form-group">
                        <label for="exampleSelectRounded0">Category Name</label>
                        <select
                          class="custom-select rounded-0"
                          id="exampleSelectRounded0"
                          ref={categoryRef}
                        >
                          <option selected value="">
                            select
                          </option>
                          {category &&
                            category.map((cat) => (
                              <option key={cat.id} value={cat.id}>
                                {cat.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      {/* brand  */}
                      <div class="form-group">
                        <label for="exampleSelectRounded0">Brand Name</label>
                        <select
                          class="custom-select rounded-0"
                          id="exampleSelectRounded0"
                          ref={brandRef}
                        >
                          <option selected value="">
                            select
                          </option>
                          {brands &&
                            brands.map((brand) => (
                              <option key={brand.id} value={brand.id}>
                                {brand.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      {/* supplier name  */}
                      <div class="form-group">
                        <label for="exampleSelectRounded0">Supplier Name</label>
                        <select
                          ref={supplierRef}
                          class="custom-select rounded-0"
                          id="exampleSelectRounded0"
                        >
                          <option selected value="">
                            select
                          </option>
                          {supplier &&
                            supplier.map((sup) => (
                              <option key={sup.id} value={sup.id}>
                                {sup.supplier_name} - {sup.shop_name}
                              </option>
                            ))}
                        </select>
                      </div>

                      {/* color  */}
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Product Color
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Product Color"
                          ref={colorRef}
                        />
                      </div>

                      {/* size  */}
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Product Size</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Product Size"
                          ref={colorRef}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button
                        onClick={handleSubmit}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* main section */}
      </div>
    </Fragment>
  );
};

export default AddProduct;
