import { Fragment, useEffect, useRef } from "react";
import BreadCrumb from "../../common/BreadCrumb";
import Utils from "../../common/ultis";
import { useRecoilState } from "recoil";
import { selectedRequisitionAtom } from "../../../states/common";
import { useNavigate } from "react-router-dom";

const PurchaseAdd = () => {
  const [purchaseInfo, setPurchaseInfo] = useRecoilState(
    selectedRequisitionAtom
  );
  const purchaseNoRef = useRef("");
  const purchaseDateRef = useRef("");
  const requisitionNoRef = useRef("");
  useEffect(() => {
    if (purchaseInfo.length === 0) {
      navigate("/requisition/approved");
    }
  }, [purchaseInfo]);

  const toCapitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const rows = purchaseInfo;
  let grandTotal = 0;
  purchaseInfo.map((product) => {
    grandTotal += product.unit_price * product.quantity;
  });

  const columns = [...new Set(rows.flatMap(Object.keys))];
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const purchase = {
      requisition_no: requisitionNoRef.current.value,
      purchase_no: purchaseNoRef.current.value,
      purchase_date: purchaseDateRef.current.value,
      product: purchaseInfo,
    };

    console.log(purchase, "820");
  };

  return (
    <Fragment>
      <Utils />
      <div className="content-wrapper">
        {/* breadcrumb */}
        <BreadCrumb
          title="Add New Purchase"
          link="/purchase"
          linkTitle="Back"
        />

        {/* breadcrumb */}
        <section className="content mt-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Add New Purchase</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Requisition No
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Requisition Number"
                            ref={requisitionNoRef}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Purchase No
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Purchase No"
                            ref={purchaseNoRef}
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Purchase No
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Purchase Date"
                            ref={purchaseDateRef}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h3 className="card-title">Purchase Details</h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          {columns.map((row, index) => (
                            <th className="tableCell">{toCapitalize(row)}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, index) => (
                          <tr key={row.id}>
                            {Object.keys(row).map((key) => (
                              <td className="tableCell" key={key}>
                                {row[key]}
                              </td>
                            ))}
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <td></td>
                          <td>Grand Total</td>
                          <td>{grandTotal}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="card-footer">
          <button
            onClick={handleSubmit}
            type="submit"
            className="btn btn-primary"
          >
            Submit
          </button>
        </div>
        {/* main section */}
      </div>
    </Fragment>
  );
};

export default PurchaseAdd;
