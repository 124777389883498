import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";

const useCategory = () => {
  const api = useApi();
  const [category, setCategory] = useState();
  useEffect(() => {
    fetchCategoryInformation();
  }, []);

  const fetchCategoryInformation = async () => {
    const categoryList = await api.getAllCategory();
    if (categoryList && categoryList.length > 0) {
      await setCategory(categoryList);
    } else {
      setCategory([]);
    }
  };

  return [category, setCategory];
};

export default useCategory;
